import { getBadge, LbButton, lbUrlNavigator, useClient, useHash, useOrganization, useToken } from "@lb/frontend";
import { authClientUrls, jsonCargo, ORGANIZATION_STATUS_OPTIONS, USER_TYPE_MASTER } from "@lb/utils";
import { Link, Stack, Typography } from "@mui/material";
import React from "react";

function Maintenance() {
  const { organization } = useOrganization();
  const { logout, client } = useClient();
  const { token } = useToken();
  const { hash } = useHash();

  const { from = null } = jsonCargo.extract(hash);

  if (token) {
    if (client?.userType === USER_TYPE_MASTER) {
      window.location.replace(process.env.REACT_APP_MASTER_URL);
    } else {
      if (organization?.status === ORGANIZATION_STATUS_OPTIONS.key.ACTIVE) {
        window.location.replace(from || process.env.REACT_APP_IAM_URL);
      }
    }
  } else {
    window.location.replace(lbUrlNavigator(authClientUrls.login.base, "AUTH"));
  }

  return (
    <Stack spacing={5} height="90vh" alignItems="center" justifyContent="center">
      <img
        src={organization?.trademark?.url || getBadge(`${organization?.abbreviation}`)}
        alt={organization?.abbreviation}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = getBadge(`${organization?.abbreviation}`);
        }}
        variant="rounded"
        style={{
          height: "100px",
          objectFit: "contain",
        }}
      />

      <Typography
        variant="h2"
        color="text.secondary"
        sx={{
          border: "10px solid",
          borderColor: "warning.light",
          color: "text.secondary",
          width: "fit-content",
          m: "auto",
          px: 3,
          fontWeight: "bolder",
          WebkitMaskImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png')",
          WebkitMaskSize: "944px 604px",
          mixBlendMode: "multiply",
        }}
      >
        UNDER MAINTENANCE
      </Typography>

      <Stack direction="row" justifyContent={"center"} spacing={3}>
        <Link href={`mailto:${organization?.email}`} variant="body2">
          Contact Organization
        </Link>
        <Link href={`mailto:${organization?.admin?.email}`} variant="body2">
          Contact Admin
        </Link>
      </Stack>

      <Stack direction="row" spacing={3} justifyContent="center" mb={2}>
        <LbButton color="error" sx={{ px: 5 }} onClick={logout}>
          Logout
        </LbButton>
      </Stack>
    </Stack>
  );
}

export default Maintenance;
